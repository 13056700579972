<template>
  <div>
    <b-button
      :title="`Update linked ${label}`"
      :data-testid="`${modal}-button`"
      size="sm"
      class="mb-25"
      :variant="modal ? 'flat-success' : 'flat-secondary'"
      :disabled="!modal"
      @click="openModal"
    >
      <slot name="icon">
        <feather-icon :icon="modal ? 'LinkIcon' : 'MinusIcon'" />
      </slot>
    </b-button>

    <span class="font-medium-2">{{ preLabel ? preLabel : 'Linked' }} {{ label }}</span>

    <b-list-group
      v-if="entityArray.length > 0"
    >
      <slot name="body" :array="entityArray">
        <list-group-item-entity
          v-for="(item,index) in entityArray"
          :key="index"
          :component="item"
          @deleted="onDeleted"
        />
      </slot>
    </b-list-group>

    <div v-else>
      <span class="ml-3 font-small-3 text-muted">No {{ preLabel ? preLabel.toLowerCase() : 'linked' }} {{ label }}</span>
    </div>
  </div>
</template>

<script>
import { BButton, BListGroup, VBTooltip } from 'bootstrap-vue'
import ListGroupItemEntity from '@/components/Forms/ListGroupItems/ListGroupItemEntity.vue'

export default {
  name: 'ListGroupEntity',
  components: {
    BButton,
    BListGroup,
    ListGroupItemEntity,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    preLabel: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    entityArray: {
      type: Array,
      required: true,
    },
    modal: {
      type: String,
      required: true,
    },
  },
  methods: {
    openModal() {
      this.$bvModal.show(this.modal)
    },
    onDeleted(id) {
      this.$emit('deleted', id)
    },
  },
}
</script>
